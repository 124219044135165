import {createRoot} from '@wordpress/element'
import React from 'react'
import Calendar from 'react-calendar'

export default class CalendarComponent {
    constructor() {
        CalendarComponent.wpet_dispo_calendar()
    }

    static wpet_dispo_calendar() {
        $('.wpetDispoCalendar').each(function () {
            function tileClassName({date, view}) {
                // Add class to tiles in month view only
                if (view === 'month') {
                    // Check if a date React-Calendar wants to check is on the list of dates to add class to
                    // let tileDate = moment(date).format('YYYY-MM-DD');

                    const tileDate = date.toISOString().split('T')[0]

                    if (Customer.wpet_disponibilites) {
                        return Customer.wpet_disponibilites[tileDate]
                    }
                }
            }

            function tileDisabled({activeStartDate, date, view}) {
                return view === 'month'
            }

            function showDoubleView() {
                return false
            }

            createRoot(this).render(
                <Calendar
                    showDoubleView={showDoubleView()}
                    showNeighboringMonth={false}
                    tileClassName={tileClassName}
                    tileDisabled={tileDisabled}
                />,
            )
        })
    }
}
